.c-quicklink {
    display: flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    background-color: black;

    &:hover {
        .c-quicklink__media {
            opacity: 0.5;
        }
    }
}

///
// Media
///
.c-quicklink__media {
    width: 100%;
    transition: opacity 0.35s;

    img {
        width: 100%;
    }
}

///
// Content
///
.c-quicklink__content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

///
// Title
///
.c-quicklink__title {
    display: inline-block;
    padding: rem(15px) rem(30px);
    color: white;
    background-color: rgba(black, 0.80);
}

///
// Link
///
.c-quicklink__link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
