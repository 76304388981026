.c-footer {
    color: white;
    text-transform: uppercase;

    p {
        margin-bottom: rem(15px);
        font-size: em(12px);

        a {
            font-size: em(12px, 12px);
        }
    }

    a {
        font-size: em(12px);
        color: white;
        text-decoration: none;
    }

    input[type="text"] {
        color: white;
        background-color: transparent;
    }

    ::-webkit-input-placeholder {
        color: white;
        line-height: 1.4;
        text-transform: uppercase;
    }

    :-moz-placeholder {
        color: white;
        line-height: 1.4;
        text-transform: uppercase;
    }

    ::-moz-placeholder {
        color: white;
        line-height: 1.4;
        text-transform: uppercase;
    }

    :-ms-input-placeholder {
        color: white;
        line-height: 1.4;
        text-transform: uppercase;
    }
}

///
// Top
///
.c-footer__top {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    background-color: color('grey', 'dark');

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }
}

///
// Bottom
///
.c-footer__bottom {
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    color: white;
    background-color: black;

    .o-col--4\@md {
        // Medium Desktops
        @include media(md) {
            text-align: right;
        }
    }

    .fa {
        width: 32px;
        height: 32px;
        margin-right: rem(10px);
        padding-top: rem(7px);
        font-size: em(21px);
        color: black;
        text-align: center;
        background-color: color('grey', 'dark');
        border-radius: 100%;
        transition: background-color 0.35s;

        @include link-states() {
            background-color: white;
        }
    }
}

///
// Footer Blocks
///
.c-footer__blocks {
    @include make-row;
}

///
// Footer Block
///
.c-footer__block {
    float: left;
    width: 100%;
    padding-left: rem(15px);
    padding-right: rem(15px);

    // Medium Max Desktops
    @include media-max(md) {
        margin-bottom: rem(30px);
    }

    // Medium Desktops
    @include media(md) {
        width: 33.3333333333%;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        // Medium Desktops
        @include media(md) {
            @include clearfix;
        }
    }

    li {
        // Medium Desktops
        @include media(md) {
            width: 48%;
            white-space: nowrap;
        }

        &:nth-of-type(odd) {
            // Medium Desktops
            @include media(md) {
                float: left;
            }
        }

        &:nth-of-type(even) {
            // Medium Desktops
            @include media(md) {
                float: right;
            }
        }
    }
}

///
// Block Title
///
.c-footer__block__title {
    display: none;
}

///
// Navigation Block
///
.c-footer__block.nav_menu-2 {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }

    // Medium Desktops
    @include media(md) {
        padding-left: rem(230px);
        background-image: url('/app/uploads/2016/11/logo-light.png');
        background-position: 15px top;
        background-repeat: no-repeat;
        background-size: 191px;
    }
}

///
// Contact Block
///
.c-footer__block.text-2 {
    // Medium Desktops
    @include media(md) {
        padding-left: rem(101px);
    }
}
