.c-btn {
    display: inline-block;
    padding: rem(10px) rem(15px);
    font-size: em(14px);
    line-height: 1.5;
    color: white;
    border: 1px solid transparent;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.35s;

    @include link-states() {
        text-decoration: none;
        background-color: color('blue');
    }
}

.c-btn--white {
    border-color: white;

    @include link-states() {
        background-color: color('blue');
    }
}
