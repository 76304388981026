.c-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z('nav');
    padding-top: rem(10px);
    transition: background-color 0.35s;

    &.is-scrolling,
    &.is-toggled {
        background-color: rgba(black, 0.62);
    }

    &:hover {
        // Medium Desktops
        @include media(md) {
            background-color: rgba(black, 0.62);
        }
    }

    .logged-in & {
        // Medium Desktops
        @include media(md) {
            top: 32px;
        }
    }

    .o-container {
        // Medium Desktops
        @include media(md) {
            @include clearfix;
        }
    }
}
