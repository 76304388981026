.c-widget {
    margin-bottom: rem(30px);
}

///
// Title
///
.c-widget__title {
    margin-bottom: rem(10px);
}
