h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: rem(20px);
    font-family: $cormorant-regular;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
}

h1,
h2 {
    color: color('green');
}

h3,
h6 {
    font-family: $lato-light;
}

h1 {
    @include font-size('h1');
}

h2 {
    @include font-size('h2');
}

h3 {
    @include font-size('h3');
}

h4 {
    @include font-size('h4');
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
}

p {
    margin-top: 0;
    margin-bottom: rem(30px);
    font-size: em(18px);

    .u-text-upper & {
        text-transform: uppercase;
    }
}

a {
    color: white;
    text-decoration: none;

    @include link-states() {
        text-decoration: underline;
    }
}

strong {
    font-family: $lato-bold;
    font-weight: 500;
}
