.c-content--aircrafts {
    clear: both;

    .aircraft-col {
        padding: 15px;
        width: 100%;

        @media (min-width: 1024px) {
            width: 33.33%;
        }
    }

    .c-quicklink__media__inner {
        background-size: cover;
        background-position: center;
        min-height: 350px;
    }

    .o-aircraft-listings {
        display: flex;
        flex-wrap: wrap;
    }

    .c-quicklink__content {
        align-items: end;

        h3 {
            margin-bottom: 0;
            width: 100%;
            text-align: center;
        }
    }
}

.single-aircrafts {
    hr.craft-sep {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .aircraft-gallery {
        margin-top: -120px;

        @media (min-width: 1024px) {
            margin-top: -300px;
        }
    }

    .aircraft-gallery__image {
        background-size: cover;
        background-position: center;
        min-height: 600px;
        width: 100%;
    }

    .aircraft-gallery__item,
    .aircraft-gallery-nav__item {
        img {
            margin: auto;
        }
    }

    .aircraft-gallery-nav__item {
        padding: 0 5px;
    }

    .aircraft-meta {
        text-align: center;
        margin-top: 45px;
    }

    .aircraft-rows {
        .aircraft-rows__row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 45px;
        }

        .aircraft-rows__row__left,
        .aircraft-rows__row__right {
            width: 100%;
            padding: 15px 0;

            @media (min-width: 1024px) {
                width: 50%;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .aircraft-rows__row__left {
            @media (min-width: 1024px) {
                padding-right: 25px;
            }
        }

        .aircraft-rows__row__right {
            @media (min-width: 1024px) {
                padding-left: 25px;
            }
        }
    }
}