.c-hero {
    padding-top: rem(115px);
    padding-bottom: rem(115px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(230px);
        padding-bottom: rem(230px);
    }
}

///
// Home
///
.c-hero--home {
    padding-top: 0;
    padding-bottom: 0;
}
