///
// Global
///
a {
    span {
        color: inherit !important;
    }
}

///
// Soliloquy
///
.soliloquy-image {
    width: 100% !important;
}

///
// Copyright
///
.c-footer__copyright {
    margin-bottom: 0 !important;
}

///
// Gravity Forms
///
.validation_message,
.gfield_description,
.ginput_counter,
.gform_validation_container {
    display: none !important;
}

///
// Gravity Forms Form Specific
///
#gform_wrapper_1 {
    margin-top: rem(40px);

    .gform_fields {
        padding-left: 0;

        // Medium Desktops
        @include media(md) {
            @include clearfix;
        }
    }

    .gfield {
        // Medium Desktops
        @include media(md) {
            width: 49%;
        }
    }

    #field_1_1,
    #field_1_2,
    #field_1_3,
    #field_1_4 {
        // Medium Desktops
        @include media(md) {
            float: left;
            clear: left;
        }
    }

    #field_1_5,
    #field_1_6 {
        // Medium Desktops
        @include media(md) {
            position: relative;
            float: right;
            clear: right;
        }
    }

    #field_1_5 {
        // Medium Desktops
        @include media(md) {
            top: neg(223px);
            margin-bottom: neg(rem(223px));
        }
    }

    #field_1_6 {
        // Medium Desktops
        @include media(md) {
            top: neg(149px);
            margin-bottom: neg(rem(149px));
        }
    }
}
