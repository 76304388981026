.o-media {
    text-align: center;

    // Medium Max Desktops
    @include media-max(md) {
        margin-bottom: rem(30px);
    }

    img {
        border-radius: 100%;
        object-fit: cover;
    }
}
