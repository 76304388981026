.c-logo {
    display: inline-block;
    width: 188px;
    height: 49px;
    background-image: url('/app/uploads/2016/11/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    // Medium Desktops
    @include media(md) {
        float: left;
        width: 287px;
        height: 81px;
    }

    .is-scrolling &,
    .is-toggled & {
        background-image: url('/app/uploads/2016/11/logo-light.png');
    }

    .c-header:hover & {
        // Medium Desktops
        @include media(md) {
            background-image: url('/app/uploads/2016/11/logo-light.png');
        }
    }
}
