.gform_fields {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.gfield_label {
    display: none;
}

.gfield {
    position: relative;
    padding-left: 0;
    @include clearfix;

    &.gfield_error {
        input,
        select,
        textarea {
            background-color: alert('error');
        }
    }
}

.gfield_error {
    input,
    textarea,
    select {
        border-color: alert('error', 'border');
    }
}

.gform_footer {
    margin-top: rem(15px);
}

.validation_error,
.gform_confirmation_message {
    margin-bottom: rem(30px);
    padding: rem(16px);
}

.validation_error {
    font-size: em(18px);
    color: alert('error', 'message');
    background-color: alert('error');
}

.gform_confirmation_message {
    font-size: em(18px);
    color: alert('success', 'message');
    background-color: alert('success');
}

.gfield_required {
    margin-left: rem(5px);
    color: alert('error', 'message');
}

.gfield_contains_required {
    .ginput_container {
        position: relative;

        input,
        textarea {
            padding-left: rem(25px);
        }

        &:after {
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 1.125em;
            color: alert('error', 'message');
            content: '\002A';
        }
    }
}
