.c-nav {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
        padding-bottom: rem(15px);
        text-align: center;
    }

    // Medium Desktops
    @include media(md) {
        float: right;
    }
}

///
// Navigation List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
        // Medium Max Desktops
        @include media-max(md) {
            margin-bottom: rem(10px);
            padding-bottom: rem(10px);
            border-bottom: 1px solid white;
        }

        // Medium Desktops
        @include media(md) {
            display: inline-block;
            margin-left: rem(30px);
            padding-top: rem(30px);
            padding-bottom: rem(30px);
            vertical-align: top;
        }
    }

    li:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }

    li:hover {
        ul {
            // Medium Desktops
            @include media(md) {
                display: block;
            }
        }
    }

    a {
        font-size: em(16px);
        color: color('blue');
        text-decoration: none;
        text-transform: uppercase;

        // Medium Desktops
        @include media(md) {
            font-size: em(14px);
        }

        .is-scrolling &,
        .is-toggled & {
            color: white;
        }

        .c-header:hover & {
            // Medium Desktops
            @include media(md) {
                color: white;
            }
        }
    }

    .active {
        > a {
            border-bottom: 1px solid $base-font-color;
        }
    }

    .is-toggled {
        .c-nav__dropdown {
            display: block;
        }
    }
}

///
// Container
///
.c-nav__container {
    // Medium Desktops
    @include media(md) {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        background-color: color('grey', 'dark');
        transition: background-color 0.35s;
    }
}

///
// Dropdown
///
.c-nav__dropdown {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    // Medium Desktop
    @include media(md) {
        padding-left: 0;
        padding-top: rem(10px);
        padding-bottom: rem(10px);
    }

    li {
        // Medium Desktop
        @include media(md) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    a {
        // Medium Desktops
        @include media(md) {
            color: white;
        }
    }
}

/*.c-nav__dropdown {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    // Medium Desktop
    @include media(md) {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: z('dropdown');
        width: 236px;
        padding: rem(15px) rem(20px);
        background-color: rgba(black, 0.62);
        box-shadow: 5px 8px 10px rgba(black, 0.25);
    }

    li {
        // Medium Desktop
        @include media(md) {
            display: block;
            margin-left: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    a {
        // Medium Desktops
        @include media(md) {
            color: white;
        }
    }
}*/
