.c-content {
    padding-top: rem(30px);
    padding-bottom: rem(30px);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }

    p:last-child {
        margin-bottom: 0;
    }

    ul {
        margin-top: 0;
        margin-bottom: rem(30px);
        padding-left: rem(20px);
    }

    li {
        margin-bottom: rem(10px);
        font-size: em(18px);
    }

    .o-col--3\@md {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }
    }
}

///
// Blue
///
.c-content--blue {
    color: white;
    background-color: color('blue');
}

///
// Services
///
.c-content--services {
    padding-top: 0;
    padding-bottom: 0;
}

///
// Contact Form
///
.c-content--form {
    color: color('green');
    background-color: color('blue');

    h1 {
        margin-bottom: 0;
    }

    li {
        margin-bottom: 0;
    }

    .home.page &,
    .blog &,
    .single & {
        background-color: color('grey', 'dark');
    }
}

///
// Background Image
///
.c-content--background {
    padding-top: rem(127px);
    padding-bottom: rem(127px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(178px);
        padding-bottom: rem(178px);
    }
}

///
// Related
///
.c-content--related {
    padding-top: 0;
    padding-bottom: 0;
}

///
// Section
///
.c-content--section {
    color: white;
    background-color: color('grey', 'dark');
}

///
// Section Blue
///
.c-content--section--blue {
    background-color: color('blue');
}

///
// Split
///
.c-content--split {
    padding-top: 0;
    padding-bottom: 0;

    .o-col {
        min-height: 300px;
    }

    .o-col--content {
        padding: rem(30px) rem(15px);

        // Large Desktops
        @include media(lg) {
            padding: rem(60px);
        }
    }

    .o-col__inner {
        // Medium Desktops
        @include media(md) {
            max-width: 626px;
            margin-left: auto;
            margin-right: 0;
        }

    }
}

///
// Split Reverse
///
.c-content--split--reverse {
    background-color: color('blue');

    .o-col__inner {
        // Medium Desktops
        @include media(md) {
            margin-left: 0;
            margin-right: auto;
        }

    }
}

///
// Map
///
.c-content--map {
    padding-top: 0;
    padding-bottom: 0;

    iframe {
        display: block;
    }
}

///
// Box
///
.c-content__box {
    max-width: rem(1140px);
    margin-left: auto;
    margin-right: auto;
    padding: rem(30px);
    color: white;
    background-color: rgba(black, 0.80);
}
