///
// Dropdown Toggle
///
.c-toggle--dropdown {
    display: none;

    // Medium Desktops
    @include media(md) {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-left: 0;
        text-align: center;
        cursor: pointer;
    }

    // Large Desktops
    @include media(lg) {
        pointer-events: none;
    }

    .c-toggle__icon {
        .is-scrolling &,
        .is-toggled & {
            color: white;
        }

        .c-header:hover & {
            // Medium Desktops
            @include media(md) {
                color: white;
            }
        }
    }
}

///
// Navigation Toggle
///
.c-toggle--nav {
    display: inline-block;
    position: relative;
    float: right;
    width: 42px;
    height: 42px;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    .c-toggle__icon {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        width: 32px;
        height: 3px;
        margin-left: auto;
        margin-right: auto;
        background-color: color('blue');
        border-radius: 100px;
        transition: transform 0.3s;

        .is-scrolling &,
        .is-toggled & {
            background-color: white;
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            width: 32px;
            height: 3px;
            margin-left: auto;
            margin-right: auto;
            background-color: inherit;
            border-radius: 100px;
            transition: transform 0.3s;
        }

        &:after {
            top: 10px;
        }

        &:before {
            bottom: 10px;
        }
    }

    &.is-active {
        .c-toggle__icon {
            background-color: transparent;

            &:after,
            &:before {
                top: 0;
                background-color: white;
            }

            &:after { transform: rotate(-45deg); }

            &:before { transform: rotate(45deg); }
        }
    }
}
