input,
select,
textarea {
    width: 100%;
    height: 44px;
    margin-bottom: rem(30px);
    padding: rem(10px);
    font-size: em(14px);
    line-height: 1.5;
    color: $base-font-color;
    background-color: white;
    border: 1px solid color('grey', 'medium');
    box-shadow: none;
    border-radius: 0;
    appearance: none;
}

textarea {
    height: 193px;
    margin-bottom: 0;
}

input[type="submit"] {
    width: auto;
    height: auto;
    margin-bottom: 0;
    padding: rem(10px) rem(20px);
    font-size: em(14px);
    letter-spacing: 1px;
    line-height: 1.5;
    color: white;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid white;
    transition: background-color 0.35s;

    @include link-states() {
        background-color: color('blue');
    }
}

::-webkit-input-placeholder {
    font-size: rem(16px);
    font-weight: 500;
    line-height: 1.5;
    color: $base-font-color;
}

::-moz-placeholder {
    font-size: rem(16px);
    font-weight: 500;
    line-height: 1.5;
    color: $base-font-color;
}

:-ms-input-placeholder {
    font-size: rem(16px);
    font-weight: 500;
    line-height: 1.5;
    color: $base-font-color;
}

:-moz-placeholder {
    font-size: rem(16px);
    font-weight: 500;
    line-height: 1.5;
    color: $base-font-color;
}
