body {
    margin: 0;
    font-size: $base-font-size;
    font-family: $lato-light;
    line-height: $base-line-height;
    letter-spacing: 1px;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
