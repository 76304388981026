.alignnone,
.alignleft,
.alignright {
    // Medium Max Desktops
    @include media-max(md) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.alignleft,
.alignright {
    margin-bottom: rem(20px);
}

.alignleft {
    // Medium Desktops
    @include media(md) {
        float: left;
        margin-right: rem(20px);
    }
}

.alignright {
    // Medium Desktops
    @include media(md) {
        float: right;
        margin-left: rem(20px);
    }
}
