.c-post {
    margin-bottom: rem(30px);
}

///
// Header
///
.c-post__header {
    margin-bottom: rem(15px);
}

///
// Title
///
.c-post__title {
    margin-bottom: 0;
}

///
// Navigation List
///
.c-post__nav-list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

///
// Navigation List Item
///
.c-post__nav-item {
    display: inline-block;
    margin-right: rem(15px);
    vertical-align: top;

    .current {
        padding: rem(5px) rem(10px);
        background-color: color('grey', 'dark');
    }
}
